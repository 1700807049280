<template>
  <div>
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <div class="block">
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      loading: true,
      link: ''
    }
  },
  created () {
    this.link = this.$route.query.link
    console.log(this.link);
    this.$toast.loading('请稍等...')
    if (this.link) {
      window.location.href = this.link

    }
  },
  methods: {

  },
  mounted () {

  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
</style>